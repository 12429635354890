import { defineMessages } from "react-intl"

const scope = "notebook"

export default defineMessages({
  headline: {
    title: {
      id: `${scope}.title`,
      defaultMessage:
        "<color>Reparatur oder Neukauf,</color><break>was lohnt sich wirklich?</break>",
    },
    headline: {
      id: `${scope}.headline`,
      defaultMessage: "Notebook-Reparatur",
    },
    text: {
      id: `${scope}.text`,
      defaultMessage:
        "Diese Frage beantworten wir Ihnen gerne und führen eine kostenlose Gerätediagnose durch. Wir reparieren täglich unzählige Notebooks verschiedener Hersteller und wissen daher genau, wann eine Reparatur möglich ist.<break>Holen Sie sich jetzt eine zweite Meinung bei uns ein, bevor Sie in eine teure Neuanschaffung investieren.</break> <break>Wir garantieren Ihnen maximale Transparenz und einen größtmöglichen Service. Als Servicewerkstatt erhalten wir zudem zahlreiche Ersatzteile, die es für Privatleute außerhalb der Garantiezeit nicht mehr gibt. </break>",
    },
  },
  diagonose: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Jetzt Schnelldiagnose einholen",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage: "Aller Hersteller und Modelle",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage: "Keine Aufwandspauschalen",
    },
    text3: {
      id: `${scope}.text3`,
      defaultMessage: "Professionelle Beratung",
    },
  },
  slogan: {
    header: {
      id: `${scope}.header`,
      defaultMessage: "Wo andere aufhören <color>fangen wir an.</color>",
    },
    middle: {
      id: `${scope}.middle`,
      defaultMessage:
        "Zeigt Ihr Notebook <b>keine Reaktion</b>, bleibt das <b>Display einfach schwarz</b> oder wird der <b>Akku nicht geladen</b> ?",
    },
  },
  panic: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "KEINE PANIK",
    },
    text1: {
      id: `${scope}.text1`,
      defaultMessage: "Sie brauchen <bold>kein</bold> neues Notebook.",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage: "WIR KRIEGEN DAS HIN !",
    },
    text3: {
      id: `${scope}.text3`,
      defaultMessage:
        "Die meisten Fehler und Defekte lassen sich schnell und kostengünstig reparieren.",
    },
    text4: {
      id: `${scope}.text4`,
      defaultMessage:
        "Bei uns gehen Sie kein Risiko ein. Es gibt keine versteckten Kosten und die Gerätediagnose ist komplett kostenfrei. Wir überprüfen Ihr Notebook und erstellen einen Kostenvoranschlag. Anschließend entscheiden Sie, ob eine Reparatur erfolgen soll.",
    },
  },
  notebook: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Notebook Reparatur vom Profi",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Egal ob Sturzschaden, Kurzschluss, Hardwarefehler oder Flüssigkeitsschaden: Wir reparieren Ihr Notebook oder Laptop professionell und kostengünstig. <break>Darüber hinaus begleiten wir Sie gerne beratend während der gesamten Reparatur und informieren Sie über jeden Handlungsschritt. Hierbei erklären wir Ihnen auf Wunsch die individuellen Abläufe der Reparatur.</break>",
    },
  },
  displayFail: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Display Fehler",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Streifen, Flecken oder eine fehlerhafte Darstellung auf dem Monitor sind ein Graus für jeden Anwender. Grund für den Fehler kann ein defekter Grafikchip sein, den es auszutauschen gilt. Wir besitzen auf diesem Gebiet über 14 Jahre Berufserfahrung und nehmen die erforderlichen Arbeiten schnell und fachkundig vor.",
    },
  },
  displayBreak: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Displaybruch",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Auch ein gebrochenes oder gerissenes Display ist kein Grund zur Sorge. Durch unseren umfangreichen Lagerbestand an unterschiedlichen Displays sind wir imstande, innerhalb von <color>24 Stunden</color> Abhilfe zu schaffen.",
    },
  },
  waterRepair: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Flüssigkeitsschaden",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Eine verschüttete Tasse Kaffee oder ein Regenguss bedeuten nicht automatisch das Ende Ihres geliebten Notebooks. Schalten Sie das Gerät in diesem Fall bitte sofort aus und trennen Sie es vom Strom. Anschließend senden oder bringen Sie das Notebook einfach schnellstmöglich zu uns. Wir sind imstande, Ihnen schnell und unkompliziert zu <color>helfen.</color>",
    },
  },
  hardwareChange: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Wechsel von Schnittstellen und Buchsen",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Wird der Akku nur sporadisch geladen kann das auf ein Wackelkontakt an der Strombuchse / Ladebuchse zurückzuführen sein. Durch das ständige An- und Ausstecken von Netzteilen verformen sich meist die Anschlüsse. Der Einsatz neuer Schnittstellen, USB Ports oder Ladebuchsen ist unkompliziert und schnell erledigt. Legen Sie es nicht darauf an und vermeiden Sie einen Geräteausfall.",
    },
  },
  shotCircuit: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Kurzschluss auf dem Logicboard",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Ihr Notebook hat einen Kurzschluss? Oft ist die Ursache eine Überspannung auf dem Mainboard. Hierbei ist es in der Regel nicht notwendig, das Mainboard komplett zu ersetzen. Wir regenerieren einfach die betroffenen Leiterbahnen und helfen Ihrem Gerät professionell wieder auf die Beine.",
    },
  },
  usb: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Einsatz neuer Buchsen und Schnittstellen",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Auch die Anschlüsse des Notebooks nehmen bei intensiver Nutzung eventuell Schaden. So ist es etwa möglich, dass sich eine Ladebuchse aufgrund häufigen An- und Aussteckens von Netzteilen verformt. In der Folge lädt der Akku nur noch unvollständig auf. <break>Wir schaffen Abhilfe und versorgen Ihr Gerät mit neuen Schnittstellen, USB-Ports und Ladebuchsen. Der Einbau erfolgt dabei schnell und unkompliziert und verhindert einen Geräteausfall.</break>",
    },
  },
  bluescreen: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Bluescreen beim Bootvorgang",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Sie kennen das vielleicht. Sie schalten Ihr Gerät ein und der Bootvorgang stoppt abrupt bei einem blauen Bildschirm. Auch wenn anscheinend nichts mehr geht – Ihre Daten sind nicht verloren. Der sogenannte Bluescreen hat viele mögliche Ursachen. Ob inkompatibler Treiber, defekte Hardware oder fehlerhaftes Dateisystem: <color>Wir finden den Fehler und beheben ihn oder sichern Ihre Daten zuverlässig.</color>",
    },
  },
  insurance: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Für die Versicherung",
    },
    content: {
      id: `${scope}.content`,
      defaultMessage:
        "Gerne erstellen wir Ihnen einen detaillierten Kostenvoranschlag für Ihre Versicherung.",
    },
  },
  price: {
    header: {
      id: `${scope}.header`,
      defaultMessage: "Priceliste",
    },
    normal: {
      fastCheck: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Schnelldiagnose",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: "kostenlos",
        },
      },
      expense: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Aufwandspauschale",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: "kostenlos",
        },
      },
      try: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Kosten bei Misserfolg",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: "kostenlos",
        },
      },
      notebookClean: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Professionelle Notebook Reinigung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 39,
        },
      },
      insurance: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Kostenvoranschlag für Versicherung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 39,
        },
      },
      express: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Express 24 Std. Bearbeitung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 49,
        },
      },
      hardwareChange: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Strombuchsen",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 59,
        },
      },
      displayChange: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Displays zzgl. Material",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 49,
        },
      },
    },
    komplex: {
      title: {
        id: `${scope}.title`,
        defaultMessage: "Komplexe Reparaturen auf Chiplevel",
      },
      battery: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Reparatur der Ladeelektronik",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
      current: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Reparatur der Stromversorgung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
      shotcircuit: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Behebung von Kurzschlüssen",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
      WaterDefect: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Flüssigkeitschaden",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 119,
        },
      },
      graphic: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Einsatz neuer Grafikchips",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 119,
        },
      },
    },
    special: {
      title: {
        id: `${scope}.title`,
        defaultMessage: "Spezial Reparaturen",
      },
      bios: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Neue Bios Chips inkl. Programmierung",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
      password: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Passwort Reset",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 49,
        },
      },
      usb: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Datenrettung aus USB-Sticks",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 49,
        },
      },
      disk: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Datenrettung aus Festplatten",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 99,
        },
      },
      board: {
        title: {
          id: `${scope}.title`,
          defaultMessage: "Platinen Reparatur von Festplatten",
        },
        price: {
          id: `${scope}.price`,
          defaultMessage: 149,
        },
      },
    },
  },
})
