import React from "react"
import { Image } from "../shared-components/images"
import {
  Container,
  HeaderBox,
  Head,
  Line,
  Mid,
  Foot,
  ImageBox,
  TextBox,
  Holder,
  Content,
} from "./styles"

const Panic = ({ head, mid, foot, source, alt, text1, text2 }) => {
  return (
    <Holder>
      <Content>
        <Container>
          <HeaderBox>
            <div>
              <Head>{head}</Head>
              <Line />
            </div>
            <Mid>{mid} </Mid>
            <Foot>{foot} </Foot>
          </HeaderBox>
          <ImageBox>
            <Image source={source} alt={alt} />
          </ImageBox>
        </Container>
      </Content>
      <TextBox>
        <p style={{ maxWidth: "920px", fontWeight: 400 }}>{text1} </p>
        <p style={{ maxWidth: "900px" }}>{text2} </p>
      </TextBox>
    </Holder>
  )
}

export default Panic
