import styled from "styled-components"

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-family: "Open Sans";
  background: #282828;
  color: white;
`

export const Header = styled.h1`
  font-family: "Open Sans";
  text-align: left;
  background: #282828;
  color: white;
  font-weight: 400;
  padding: 0 0 3rem 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding: 0 1.5rem;
    line-height: 1.3;
    margin: 0.5rem auto 2rem auto;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContentHolder = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem;
  @media (max-width: 425px) {
    padding: 0;
    margin: 1rem auto;
  }
  @media (max-width: 375px) {
    padding: 0;
    margin: 1rem auto;
  }
`

export const ImageHolder = styled.div`
  width: 50%;
  margin: 2rem 0;

  @media (max-width: 768px) {
    width: 100vw;
    margin-top: -0.001rem;
  }
`
export const TextHolder = styled.div`
  width: 60%;
  margin-top: 15px;
  padding: 0 4rem;
  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
  }
`

export const Text = styled.p`
  color: white;
  font-family: "Open Sans";
  font-size: 1.3rem;
  font-weight: 300;
  padding: 0 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`
