import React from "react"
import { Image } from "../../../common/shared-components/images"
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
  ContentHolder,
} from "./styles"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const BluescreenNotebook = () => {
  const intl = useIntl()
  return (
    <Container>
      <ContentHolder>
        <Content>
          <TextHolder>
            <Header>{intl.formatMessage(i18n.bluescreen.title)}</Header>
            <Text>
              {intl.formatMessage(i18n.bluescreen.content, {
                color: str => <span style={{ color: "#5CE1E6" }}>{str} </span>,
              })}
            </Text>
          </TextHolder>
          <ImageHolder>
            <Image source="blueScreen.png" alt="Water defect" />
          </ImageHolder>
        </Content>
      </ContentHolder>
    </Container>
  )
}

export default BluescreenNotebook
