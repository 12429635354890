import React, { useState } from "react"
import { Headline } from "../../common/headline"
import styled from "styled-components"
import { FiChevronDown } from "react-icons/fi"

import PriceList from "../../common/table"

const macPriceListSimple = [
  {
    repair: "Schnelldiagnose",
    price: "kostenlos",
  },
  {
    repair: "Aufwandspauschale",
    price: "kostenlos",
  },
  {
    repair: "Kosten bei Misserfolg",
    price: "kostenlos",
  },
  {
    repair: "Professionelle Notebook Reinigung",
    price: "49 €",
  },
  {
    repair: "Kostenvoranschlag für Versicherung",
    price: "39 €",
  },
  {
    repair: "Express 24 Std. Bearbeitung",
    price: "49 €",
  },
  {
    repair: "Einsatz neuer Strombuchsen",
    price: "ab 59 €",
  },
  {
    repair: "Einsatz neuer Displays ",
    material: "zzgl. Material",
    price: "ab 49 €",
  },
]

const macPriceKomplex = [
  {
    repair: "Reparatur der Ladeelektronik",
    price: "ab 99 €",
  },
  {
    repair: "Reparatur der Stromversorgung",
    price: "ab 99 €",
  },
  {
    repair: "Behebung von Kurzschlüssen",
    price: "ab 99 €",
  },
  {
    repair: "Flüssigkeitschaden",
    material: "Wechsel korrodierter Bauteile",
    price: "ab 119 €",
  },
  {
    repair: "Einsatz neuer Grafikchips",
    price: "ab 119 €",
  },
]

const macPriceSpecial = [
  {
    repair: "Neue Bios Chips inkl. Programmierung",
    price: "ab 99 €",
  },
  {
    repair: "Passwort Reset",
    price: "ab 49 €",
  },
  {
    repair: "Datenrettung aus USB-Sticks",
    price: "ab 49 €",
  },
  {
    repair: "Datenrettung aus Festplatten",
    price: "ab 99 €",
  },
  {
    repair: "Platinen Reparatur von Festplatten",
    price: "ab 149 €",
  },

]

const Container = styled.div`
  width: 100%;
  text-align: center;
  background: #eee;
  margin: 0 auto;
 
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  
`

const NotebookPrice = () => {
  const [show, setShow] = useState(false)
  return (
    <Container>
      <Button onClick={() => setShow(!show)}>
        <Headline type="h4" textAlign="center" margin=".5rem auto">
          Preisliste Notebook <FiChevronDown />
        </Headline>
      </Button>
      {show && (
        <Content>
          <PriceList repairPriceList={macPriceListSimple} />
          <Headline
            type="p"
            fontWeight="100"
            textAlign="center"
            color="#2855a8"
            margin=".5rem auto"
          >
            Komplexe Reparaturen auf Chiplevel
          </Headline>
          <PriceList repairPriceList={macPriceKomplex} />
          <Headline
            type="p"
            fontWeight="100"
            textAlign="center"
            color="#2855a8"
            margin=".5rem auto"
          >
            Spezial Reparaturen
          </Headline>
          <PriceList repairPriceList={macPriceSpecial} />
        </Content>
      )}
    </Container>
  )
}

export default NotebookPrice
