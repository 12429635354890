import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  font-family: "Open Sans";
  background: #282828;
`

export const Header = styled.h1`
  width: 100%;
  font-family: "Open Sans";
  text-align: left;
  background: #282828;
  color: white;
  margin-bottom: 2rem;
  @media (max-width: 425px) {
    font-size: 1.8rem;
    line-height: 1.3;
    text-align: center;
  }
  @media (max-width: 375px) {
    padding: 0;
    text-align: center;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  background: none;
  align-items: center;
  margin-top: -25px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`
export const ContentHolder = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 3rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`

export const ImageHolder = styled.div`
  width: 40%;
  margin: 2rem auto;

  @media (max-width: 768px) {
    width: 100vw;
    margin-top: 0;
  }
`
export const TextHolder = styled.div`
  box-sizing: border-box;
  width: 50%;
  padding: 0 6rem;
  @media (max-width: 768px) {
    width: 100vw;
    padding: 1.5rem;
  }
`

export const Text = styled.p`
  font-family: "Open Sans";
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
`
