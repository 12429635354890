import React from "react";
import { Image } from "../../../common/shared-components/images";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";
import { Container, Header, ImgHolder, Text } from "./styles";

const DisFailNotebook = () => {
  const intl = useIntl();
  return (
    <Container>
      <Header>{intl.formatMessage(i18n.displayFail.title)} </Header>
      <ImgHolder>
        <Image source="notebookDisplayFail.jpg" alt="Display Failure" />
      </ImgHolder>
      <Text>
        {intl.formatMessage(i18n.displayFail.content, {
          break: (str) => (
            <span>
              <br />
              <br />
              {str}
            </span>
          ),
        })}
      </Text>
    </Container>
  );
};

export default DisFailNotebook;
