import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  font-family: "Open Sans";
  background: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding-top: 3rem;
`

export const Content = styled.div`
  width: 100%;

  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #282828;
  color: white;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const TextBox = styled.div`
  width: 50%;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1.5rem;
    margin: 0.9rem 0 0 0;
  }
`

export const ImageBox = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100vw;
  }
`

export const Header = styled.h1`
  font-family: "Open Sans";
  font-size: 4rem;
  padding: 0 2rem;
  font-weight: 300;
  line-height: 4.5rem;
  @media (max-width: 768px) {
  
    font-size: 2rem;
    line-height: 2.4rem;
   
`

export const Text = styled.p`
  box-sizing: border-box;
  font-family: "Open Sans";
  font-size: 1.8rem;
  font-weight: 300;
  width: 1000px;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1.5rem;
    font-size: 1.3rem;
    text-align: left;
  }
`

export const Footer = styled.div`
  box-sizing: border-box;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  @media (max-width: 768px) {
    height: 200px;
  }
`
