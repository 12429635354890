import styled from "styled-components"

export const Container = styled.div`
  box-sizing: border-box;
  background-color: #f5f5f4;
  width: 100%;
  font-family: "Open Sans";
  font-weight: 300;
`

export const Table = styled.table`
  box-sizing: border-box;
  table-layout: fixed;
  width: 100%;
`

export const Left = styled.td`
  font-size: 1rem;

  &:first-child {
    width: 60%;
    padding-left: 10px;
  }
`
export const Right = styled.td`
  font-size: 0.9rem;

  &:last-child {
    padding-right: 10px;
    width: 32%;
  }
`
export const Material = styled.td`
  font-size: 0.6rem;
  line-height: 15px;
  width: 30%;
`

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #d9d9d9;
  }
  &:nth-child(odd) {
    background-color: #f5f5f4;
  }
`
