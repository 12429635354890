import styled from "styled-components"

export const Holder = styled.div`
  width: 100%;
  font-family: "Open Sans";
  background: black;
  margin-top: 1rem;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  background: black;
  color: white;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    border: none;
    border-radius: 0;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`

export const HeaderBox = styled.div`
  box-sizing: border-box;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  justify-content: space-evenly;
  text-align: left;
  @media (max-width: 768px) {
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    text-align: center;
  }
`

export const Head = styled.h1`
  font-family: "Open Sans";
  font-size: 3rem;
  font-weight: 300;
  padding: 0.5rem 0;
  text-align: center;
`

export const Line = styled.div`
  border-bottom: 2px solid #35c4e6;
  width: 250px;
  margin: 0 auto;
  margin-top: -15px;
  @media (max-width: 768px) {
    margin: -20px auto 15px auto;
  }
  @media (max-width: 320px) {
    width: 230px;
  }
`

export const Mid = styled.p`
  font-family: "Open Sans";
  font-size: 1.3rem;
  font-weight: 300;
  margin: 0 auto;
  @media (max-width: 425px) {
    font-size: 1.2rem;
    margin: 0.5rem auto;
  }
  @media (max-width: 375px) {
    font-size: 1.1rem;
    margin: 1rem auto;
  }
  @media (max-width: 320px) {
    font-size: 1rem;
  }
`

export const Foot = styled.h3`
  margin: 0 auto;
  font-size: 2rem;
  font-family: "Open Sans";
  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.8rem;
  }
  @media (max-width: 375px) {
    font-size: 1.3rem;
  }
`

export const ImageBox = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100vw;
  }
`

export const TextBox = styled.div`
  box-sizing: border-box;
  padding-top: 2rem;
  background: #d9d9d9;
  width: 100%;
  font-family: "Open Sans";
  font-size: 1.5rem;
  font-weight: 300;
  color: black;
  text-align: left;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    justify-content: center;
    font-size: 1.3rem;
    height: 500px;
    padding: 0 1.5rem;
  }
`
