import React from "react";
import { Image } from "../../../common/shared-components/images";
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
  ContentHolder,
} from "./styles";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const DisplayDefectNotebook = () => {
  const intl = useIntl();
  return (
    <Container>
      <ContentHolder>
        <Header>{intl.formatMessage(i18n.displayBreak.title)}</Header>
        <Content>
          <ImageHolder>
            <Image source="displayBreak.jpeg" alt="Display defect" />
          </ImageHolder>
          <TextHolder>
            <Text>
              {intl.formatMessage(i18n.displayBreak.content, {
                color: (str) => (
                  <span style={{ color: "#5CE1E6" }}>{str} </span>
                ),
              })}
            </Text>
          </TextHolder>
        </Content>
      </ContentHolder>
    </Container>
  );
};

export default DisplayDefectNotebook;
