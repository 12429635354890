import React from "react"
import {
  Container,
  Content,
  TextBox,
  ImageBox,
  Header,
  Text,
  Footer
} from "./styles"
import { Image } from "../../common/shared-components/images"


const Slogan = ({ header, middle, source, alt }) => {
  return (
      <Container>
        <Content>
          <TextBox>
            <Header>{header}</Header>
          </TextBox>
          <ImageBox>
            <Image source={source} alt={alt} />
          </ImageBox>
        </Content>
        <Footer>
        <Text>{middle}</Text>
        </Footer>
      </Container>
  )
}

export default Slogan
