import styled from "styled-components"

export const Container = styled.div`
  width: 100vw;
  background: #d9d9d9;
`

export const Header = styled.h1`
  font-family: "Open Sans";
  text-align: center;
  background: #d9d9d9;
  margin: 0;
  padding: 1.5rem;
  @media (max-width: 768px) {
    width: 100vw;
    font-size: 2rem;
    padding: 1.5rem 0;
  }
`

export const ImgHolder = styled.div`
  max-width: 1100px;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Text = styled.p`
  box-sizing: border-box;
  font-family: "Open Sans";
  max-width: 1000px;
  margin: 3rem auto 6rem auto;
  font-size: 1.3rem;
  font-weight: 300;
  @media (max-width: 768px) {
    margin: 2rem auto;
    width: 100%;
    padding: 0 1.5rem;
  }
`
