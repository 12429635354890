import React from "react"
import Page from "../components/common/page"

import { ContentRow } from "../components/common/shared-components/layouts"
import NotebookBulletText from "../components/page-components/notebook/bullet-text"
import SloganNote from "../components/page-components/notebook/slogan"
import RepairNote from "../components/page-components/notebook/repair"

import NotebookPrice from "../components/page-components/notebook/priceList"
import PanicNotebook from "../components/page-components/notebook/panic"
import WaterDefectNotebook from "../components/page-components/notebook/water-defect"
import DisplayDefectNotebook from "../components/page-components/notebook/display-break"
import DisFailNotebook from "../components/page-components/notebook/display-fail"
import ShotCircuitNotebook from "../components/page-components/notebook/shot-circuit"
import BluescreenNotebook from "../components/page-components/notebook/bluescreen"
import UsbNotebook from "../components/page-components/notebook/usb"
import NotebookRepair from "../components/page-components/notebook/start/newIndex"

const CONTENT_ROWS = [
  [<NotebookRepair/>],
  [<NotebookBulletText />],
  [<SloganNote />],
  [<PanicNotebook />],
  [<RepairNote />],
  [<WaterDefectNotebook />],
  [<DisplayDefectNotebook />],
  [<DisFailNotebook />],
  [<ShotCircuitNotebook />],
  [<BluescreenNotebook />],
  [<UsbNotebook />],
  [<NotebookPrice />],
]

const Notebook = () => {
  return (
    <Page title="notebook">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  )
}

export default Notebook
