import React from "react";
import { Image } from "../../../common/shared-components/images";
import {
  Container,
  Content,
  ContentText,
  Headline,
  ImgHolder,
  NotebookText,
  Text,
  Span,
  MobileHeadline,
} from "./newStyles";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const NotebookRepair = () => {
  const intl = useIntl();
  return (
    <Container>
      <MobileHeadline>
        {intl.formatMessage(i18n.headline.headline)}{" "}
      </MobileHeadline>
      <Content>
        <ImgHolder>
          <Image source="notebookRepair.jpg" alt="notebookRepair" />
          <NotebookText>
            {intl.formatMessage(i18n.headline.title, {
              break: (str) => (
                <Span color="white">
                  {" "}
                  <br />
                  {str}{" "}
                </Span>
              ),
              color: (str) => <Span color="white4">{str}</Span>,
            })}
          </NotebookText>
        </ImgHolder>
        <ContentText>
          <Headline>{intl.formatMessage(i18n.headline.headline)} </Headline>
          <Text>
            {intl.formatMessage(i18n.headline.text, {
              break: (str) => (
                <span>
                  <br /> <br />
                  {str}{" "}
                </span>
              ),
            })}
          </Text>
        </ContentText>
      </Content>
    </Container>
  );
};

export default NotebookRepair;
