import React from "react"
import {
  Container,
  Tr,
  Material,
  Left,
  Right,
  Table,
} from "./styles"



const PriceList = ({ repairPriceList }) => {
  const priceList = repairPriceList
  return (
    <Container>
      <Table>
        <tbody>
          {priceList.map((item, index) => (
            <Tr key={index}>
              <Left>{item.repair} </Left>
              <Material>{item.material}</Material>
              <Right>{item.price}</Right>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default PriceList
