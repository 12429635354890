import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: black;
  color: white;
`

export const Headline = styled.h1`
  font-family: "Open Sans";
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    display: none;
  }
`

export const MobileHeadline = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  margin: 2rem 0 0 0;
  font-family: "Open Sans";
  @media (min-width: 767px) {
    display: none;
  }
`

export const Content = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-gap: 3rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1.5rem;
  }
`

export const ContentText = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.5rem;
`

export const Text = styled.p`
  font-family: "Open Sans";
  font-size: 1.2rem;
  font-weight: 100;
`

export const ImgHolder = styled.div`
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    margin-top: -4rem;
    width: 120%;
  }
`

export const NotebookText = styled.h2`
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4vw;
  position: absolute;
  top: 35%;
  left: 50%;
  line-height: 3.5;
  transform: translate(-50%, -50%);
  z-index: 999;
  @media (max-width: 768px) {
    line-height: 2.1;
    font-size: 3.5vw;
  }
`

export const Span = styled.span`
  color: ${props => props.color};
`
