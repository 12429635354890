import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";
import Repair from "../../../common/repair-profi";

const RepairNote = () => {
  const intl = useIntl();
  return (
    <Repair
      header={intl.formatMessage(i18n.notebook.title)}
      source="notebookProfi.jpeg"
      alt="graphic repair"
      text1={intl.formatMessage(i18n.notebook.content, {
        break: (str) => (
          <span>
            <br />
            <br />
            {str}
          </span>
        ),
      })}
    />
  );
};

export default RepairNote;
