import React from "react"
import styled from "styled-components"
import { BsCheckCircle } from "react-icons/bs"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  background: #d9d9d9;
  padding: 2rem 0;
  margin-top: 1rem;

  @media (max-width: 768px) {
    padding: 1.5rem 0;
  }
`
const Content = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
`

const BulletList = styled.div`
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  place-items: center;
  font-size: 3rem;
  margin: -20px auto;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 0;
  }
`

const Text = styled.p`
  min-width: 250px;
  font-family: "Open Sans";
  font-size: 2rem;
  margin-top: 21px;
  color: black;
  font-weight: 300;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
    margin: 0.5rem;
  }
`

const Bullets = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    max-width: 325px;
    flex-direction: column;
    margin: 0 auto;
  }
`

const Header = styled.h1`
  color: black;
  font-family: "Open Sans";
  text-align: center;
  margin: 0.5rem 0 3rem 0;
  font-weight: 400;
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
`

const BulletText = ({ headline, text1, text2, text3 }) => {
  return (
    <Container>
      <Content>
        <Header>{headline}</Header>
        <Bullets>
          <BulletList>
            <BsCheckCircle color="rgb(41,151,255)" />
            <Text>{text1}</Text>
          </BulletList>
          <BulletList>
            <BsCheckCircle color="rgb(41,151,255)" />
            <Text>{text2}</Text>
          </BulletList>
          <BulletList>
            <BsCheckCircle color="rgb(41,151,255)" />
            <Text>{text3}</Text>
          </BulletList>
        </Bullets>
      </Content>
    </Container>
  )
}

export default BulletText
