import React from "react";
import { Image } from "../../../common/shared-components/images";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";
import { Container, Header, ImgHolder, Text, ContentHolder } from "./styles";

const UsbNotebook = () => {
  const intl = useIntl();
  return (
    <Container>
      <Header>{intl.formatMessage(i18n.usb.title)} </Header>
      <ContentHolder>
        <ImgHolder>
          <Image source="usb.jpeg" alt="usb change" />
        </ImgHolder>
        <Text>
          {intl.formatMessage(i18n.usb.content, {
            break: (str) => (
              <span>
                <br />
                <br />
                {str}
              </span>
            ),
          })}
        </Text>
      </ContentHolder>
    </Container>
  );
};

export default UsbNotebook;
