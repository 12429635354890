import React from "react";
import Panic from "../../../common/panic";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const PanicNotebook = () => {
  const intl = useIntl();
  return (
    <Panic
      head={intl.formatMessage(i18n.panic.title)}
      mid={intl.formatMessage(i18n.panic.text1, {
        bold: (str) => <b>{str}</b>,
      })}
      foot={intl.formatMessage(i18n.panic.text2)}
      source="panik.jpeg"
      alt="no panic"
      text1={intl.formatMessage(i18n.panic.text3)}
      text2={intl.formatMessage(i18n.panic.text4)}
    />
  );
};

export default PanicNotebook;
