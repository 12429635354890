import React from "react"
import { Image } from "../shared-components/images"
import {
  Container,
  Content,
  Header,
  ImageHolder,
  Text,
  TextHolder,
  ContentHolder,
} from "./styles"

const Repair = ({ header, text1, text2, source, alt }) => {
  return (
    <Container>
      <ContentHolder>
        <Content>
          <ImageHolder>
            <Image source={source} alt={alt} />
          </ImageHolder>
          <TextHolder>
            <Header>{header}</Header>
            <Text>{text1}</Text>
            <Text>{text2}</Text>
          </TextHolder>
        </Content>
      </ContentHolder>
    </Container>
  )
}

export default Repair
